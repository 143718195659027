<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <v-form ref="formProductor">
          <h1><v-icon class="iconTitle">mdi-account-group</v-icon> Ficha de cliente</h1><br>
          <v-layout>
            <v-flex xs4>
              <v-text-field
                label="Nombre"
                outlined
                v-model="nombre"
                :rules="rules.required"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                label="CIF"
                outlined
                v-model="cif"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Teléfono"
                outlined
                v-model="telefono"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Email"
                outlined
                v-model="email"
                :rules="rules.required"
                :disabled="isDisabledLogin"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs4>
              <v-text-field
                label="Dirección"
                outlined
                v-model="direccion"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                label="Código postal"
                outlined
                v-model="cp"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Localidad"
                outlined
                v-model="localidad"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Provincia"
                outlined
                v-model="provincia"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <h2 style="margin: 10px 0">Datos de acceso a oliCloud</h2>
          <v-layout>
            <v-flex xs3>
              <v-text-field
                label="Usuario"
                outlined
                v-model="email"
                :rules="rules.required"
                :disabled="isDisabledLogin"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Contraseña"
                outlined
                v-model="contrasena"
                :rules="rules.required"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
                :disabled="isDisabledLogin"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btnCustom save" :loading="loading" @click="saveClient"><v-icon class="btnIcon">mdi-content-save</v-icon> Guardar</v-btn>
        <v-btn class="btnCustom" :disabled="isDisabledButton" @click="newLand"><v-icon class="btnIcon">mdi-tree</v-icon> Nueva finca</v-btn>
      </v-card-actions>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="fincas"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              {{ item.comentario }}
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editarFinca(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="eliminarFinca(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL, provincias, municipios} from '../components/constants.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
export default {
  name: 'Productores',
  data () {
    return {
      rules: {
        required: [val => (val || '').length > 0 || 'Este campo es obligatorio'],
      },
      loading: false,
      showPass: true,
      nombre: '',
      cif: '',
      telefono: '',
      email: '',
      direccion: '',
      cp: '',
      localidad: '',
      provincia: '',
      contrasena: '',
      isDisabledLogin: false,
      isDisabledButton: true,
      headers: [
        {
          text: 'Identificador',
          align: 'start',
          sortable: false,
          value: 'identificador',
        },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Superficie (Ha)', value: 'superficie' },
        { text: 'Plantas', value: 'plantas' },
        { text: 'Variedad', value: 'variedad' },
        { text: 'Polígono', value: 'poligono' },
        { text: 'Parcela', value: 'parcela' },
        { text: 'Recinto', value: 'recinto' },
        { text: 'Localidad', value: 'localidad' },
        { text: 'Provincia', value: 'provincia' },
        { text: '', value: 'actions', sortable: false },
      ],
      fincas: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.getClient();
  },
  methods:{
    async saveClient(){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      this.loading = true;
      const data = {
        nombre: this.nombre,
        NIF: this.cif,
        telefono: this.telefono,
        direccion: this.direccion,
        localidad: this.localidad,
        provincia: this.provincia,
        cp: this.cp,
        password: this.contrasena,
      };
      const response = await fetch(
        `${BASE_URL}/productor`,
        {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(data),
        }
      );
      switch (response.status) {
        case 200:
          this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
          this.loading = false;
          break;
        default:
          this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
          this.loading = false;
          break;
      }
    },
    async getClient(){
      const response = await genericReload(this.$store.state.auth, `productor`);
      if (response.status === 200) {
        const json = await response.json();
        this.$store.commit('modifyNameUser',json.body[0].nombre);
        this.nombre = json.body[0].nombre;
        this.cif = json.body[0].NIF;
        this.email = json.body[0].email;
        this.telefono = json.body[0].telefono;
        this.localidad = json.body[0].localidad;
        this.direccion = json.body[0].direccion;
        this.provincia = json.body[0].provincia;
        this.cp = json.body[0].cp;
        this.isDisabledLogin = true;
        this.isDisabledButton = false;
        this.readFinca();
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos", "Cerrar", "red");
      }
    },
    clear(){
      this.isDisabledLogin = false;
      this.isDisabledButton = true;
      this.fincas = [];
    },
    async newLand(){
      this.$refs.dialog.setNew("Nueva finca", "", "newLand").then(async(confirm) => {
        if(confirm.response){
          const data = {
            identificadorFinca: confirm.identificador, 
            nombre: confirm.nombre, 
            superficie: confirm.superficie,
            medidaSuperficie: "HA",
            plantas: confirm.plantas,
            tipoPlanta: confirm.variedad,
            poligono: confirm.poligono,
            parcela: confirm.parcela,
            recinto: confirm.recinto,
            localidad: confirm.localidad,
            provincia: confirm.provincia,
          }
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/finca`,
            {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
              await this.readFinca();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
              break;
          }
        }
      });
    },
    async readFinca(){
      await this.$store.dispatch("getFincas");
      this.fincas = [];
      const json = this.$store.state.fincas;
      let pro;
      for(let i = 0; i<json.length; i++){
        pro = provincias.find(elem => elem.id == json[i].provincia);
        this.fincas.push({
          id: json[i].id, 
          identificador: json[i].identificadorFinca, 
          nombre: json[i].nombre, 
          superficie: json[i].superficie,
          plantas: json[i].plantas,
          variedad: json[i].tipoPlanta,
          poligono: json[i].poligono,
          parcela: json[i].parcela,
          recinto: json[i].recinto,
          localidad: json[i].localidad,
          provincia: pro.nm,
        });
      }
    },
    async editarFinca(item){
      const pro = provincias.find(elem => elem.nm == item.provincia);
      this.$refs.dialog.setNew("Editar finca", "", "editLand", [item.identificador, item.nombre, item.superficie, item.plantas, item.variedad, item.poligono, item.parcela, item.recinto, pro.id, item.localidad]).then(async(confirm) => {
        if(confirm.response){
          const data = {
            idProductor: this.selectClient, 
            identificadorFinca: confirm.identificador, 
            nombre: confirm.nombre, 
            superficie: confirm.superficie,
            plantas: confirm.plantas,
            tipoPlanta: confirm.variedad,
            poligono: confirm.poligono,
            parcela: confirm.parcela,
            recinto: confirm.recinto,
            provincia: pro.id,
            localidad: confirm.localidad,
          };
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/finca/${item.id}`,
            {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Finca editada correctamente", "Ok", "green");
              this.readFinca();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Finca no editada", "Cerrar", "red");
              break;
          }
        }
      })
    },
    async eliminarFinca(item){
      this.$refs.dialog.setNew("Eliminar finca", "¿Estás seguro de querer eliminar la finca? Este paso es irreversible y perderá toda la información asociada a dicha finca.").then(async(confirm) => {
        if(confirm.response){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/finca/${item.id}`,
            {
              method: 'DELETE',
              headers: headers,
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Finca eliminada correctamente", "Ok", "green");
              this.readFinca();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Finca no eliminada", "Cerrar", "red");
              break;
          }
        }
      })
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>